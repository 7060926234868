<template>
  <v-switch
    v-model="currentValue"
    class="switch-picker"
    v-bind="$attrs"
    inset
    :label="label"
    :disabled="disabled"
    v-on="$listeners"
  ></v-switch>
</template>

<script lang="ts">
import Vue from "vue"
import { formField } from "@evercam/ui"

export default Vue.extend({
  name: "SwitchField",
  mixins: [formField],
})
</script>

<style>
.switch-picker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.switch-picker .v-messages {
  display: none !important;
}
</style>
