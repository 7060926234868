<template>
  <CtkDateTimePicker
    class="evercam-field caption"
    :value="currentValue"
    :label="`Please select the ${label}`"
    :disabled="disabled"
    format="YYYY-MM-DDTHH:mm:ss.SSS[Z]"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script lang="ts">
import Vue from "vue"
import CtkDateTimePicker from "@evercam/shared/components/CtkDateTimePicker.vue"
import { formField } from "@evercam/ui"

export default Vue.extend({
  name: "DateTimeField",
  components: {
    CtkDateTimePicker,
  },
  mixins: [formField],
})
</script>

<style>
.date-time-picker__text-field {
  font-size: 14px;
}
</style>
