<template>
  <div class="copilot-tc-result__media-clip">
    <MediaClip
      :media="mediaClip"
      :camera-name="cameraName"
      :refresh-timeout-minutes="Infinity"
      :refresh-interval-ms="2000"
      :with-refresh="withRefresh"
      :project-exid="projectExid"
      :clickable="false"
    >
      <template #top-right-append>
        <v-btn
          v-if="withMediaHubButton"
          class="e-chat-input__submit-btn"
          :href="`/v2/projects/${mediaClip.projectExid}/media-hub`"
          target="_blank"
          color="primary"
          small
          :aria-label="$t('aria.open_external_link')"
        >
          <span class="e-text-xs e-font-medium"> Open in Media Hub </span>
        </v-btn>
      </template>
    </MediaClip>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import {
  type CameraExid,
  type CamerasByExid,
  type Media,
} from "@evercam/shared/types"
import MediaClip from "@evercam/shared/components/medias/MediaClip"

export default Vue.extend({
  name: "CopilotToolCallResultMediaClip",
  components: {
    MediaClip,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
    mediaClip: {
      type: Object as PropType<Media>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    withRefresh: {
      type: Boolean,
      default: true,
    },
    withMediaHubButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    cameraExid(): CameraExid | undefined {
      return this.mediaClip?.cameraExid
    },
    projectExid(): string {
      return this.mediaClip.projectExid as string
    },
    cameraName(): string {
      return (this.mediaClip?.cameraName ||
        this.camerasByExid[this.cameraExid!]?.name ||
        this.mediaClip.title?.split(" - ")?.[0]) as string
    },
    mediaUrl(): string {
      return `/v2/projects/${this.mediaClip.project_exid}/media-hub/${this.mediaClip.exid}`
    },
  },
})
</script>

<style lang="scss">
.copilot-tc-result__media-clip {
  width: 500px;
}
</style>
