<template>
  <div class="e-flex e-flex-col e-justify-start e-gap-2 e-items-start">
    <span class="e-font-medium e-text-sm">{{ label }}</span>
    <EToggleSwitch
      v-model="currentValue"
      :options="options"
      :disabled="disabled"
      v-bind="$attrs"
      v-on="$listeners"
    />
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import { type EToggleSwitchOption, formField } from "@evercam/ui"

export default Vue.extend({
  name: "MultiOptionsField",
  mixins: [formField],
  props: {
    options: {
      type: Array as PropType<EToggleSwitchOption[]>,
      required: true,
    },
  },
})
</script>

<style scoped></style>
