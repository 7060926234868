<template>
  <div
    class="copilot-tc-result"
    :class="{
      'e-w-full': !isMediaResult,
    }"
  >
    <CopilotToolCallResultMediaClip
      v-if="isMediaResult"
      :media-clip="mediaClip"
      :cameras-by-exid="camerasByExid"
      :with-refresh="withRefresh"
      v-bind="$attrs"
    />
    <CopilotChart
      v-else-if="isChartResult"
      :options="chartData"
      :user="user"
      :project="project"
      :with-export-btn="withExportChartBtn"
    />
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import {
  type CamerasByExid,
  type CopilotSystemToolCallResponse,
  CopilotToolId,
  type Media,
  type Project,
  User,
} from "@evercam/shared/types"
import CopilotToolCallResultMediaClip from "@evercam/shared/components/copilot/CopilotToolCallResultMediaClip.vue"
import { camelizeKeys } from "humps"
import CopilotChart from "@evercam/shared/components/copilot/CopilotChart.vue"
import Highcharts from "highcharts"

export default Vue.extend({
  name: "CopilotToolCallResult",
  components: {
    CopilotToolCallResultMediaClip,
    CopilotChart,
  },
  props: {
    result: {
      type: Object as PropType<CopilotSystemToolCallResponse>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<Record<string, Project>>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    withRefresh: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object as PropType<User>,
      default: null,
    },
    withExportChartBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    chartData(): Record<string, unknown> {
      const chartDefinition = (
        this.result.output as { definition: Highcharts.Options }
      ).definition
      let chartData = {}
      try {
        chartData = JSON.parse(chartDefinition as string)
      } catch (e) {
        console.error("Failed to parse system tool call chart definition")
        this.$errorTracker?.save(e, "Copilot")
        this.$emit("error")
      }

      return chartData
    },
    isChartResult(): boolean {
      return this.result.toolId === CopilotToolId.RenderCharts
    },
    isMediaResult(): boolean {
      return (
        this.result.toolId === CopilotToolId.CreateClip ||
        this.result.toolId === CopilotToolId.CreateTimelapse
      )
    },
    mediaClip(): Media {
      return camelizeKeys(this.result.output as Media) as Media
    },
    project(): Project {
      const { projectExid } = this.result.output as Record<string, string>

      return this.projectsByExid[projectExid]
    },
  },
})
</script>
