<template>
  <v-hover v-slot="{ hover }">
    <v-card
      v-resize-observer="onResize"
      :class="getHoverStyle(hover)"
      :loading="withLoadingAnimation && isPending"
      :nuxt="isMediaClipClickable"
      :to="getMediaLink"
      class="rounded-lg background overflow-hidden e-h-full !e-shadow-lg e-text-white"
    >
      <div
        class="media-append-top e-flex e-items-start e-justify-between"
        style="width: 97%"
      >
        <MediaTypeBadge :media-item="clip" />
        <slot name="top-right-append" />
      </div>
      <v-responsive
        v-if="isFailed"
        :aspect-ratio="16 / 9"
        class="file-clip-background"
        content-class="position-relative rounded-t-lg e-w-full e-h-full d-flex justify-center align-center"
        :height="imageHeight"
      >
        <div
          class="e-flex e-w-full e-flex-col e-gap-1 e-items-center e-justify-center"
        >
          <EIcon
            :icon="`far fa-exclamation-triangle fa-5x`"
            color="secondary--text"
            :size="$vuetify.breakpoint.mdAndDown ? '4xl' : '6xl'"
          />
          <h4 class="secondary--text">{{ failedMediaPlaceholderText }}</h4>
        </div>

        <v-card-title class="img-title white--text" v-text="clip.title" />
      </v-responsive>
      <v-responsive
        v-else-if="isDocFile"
        :aspect-ratio="16 / 9"
        class="file-clip-background"
        content-class="position-relative rounded-t-lg e-w-full e-h-full d-flex justify-center align-center"
        :height="imageHeight"
      >
        <EIcon
          :icon="`far ${mediaFileIcon} fa-5x`"
          color="secondary--text"
          :size="$vuetify.breakpoint.mdAndDown ? '6xl' : '8xl'"
        />
        <v-card-title class="img-title white--text" v-text="clip.title" />
      </v-responsive>

      <v-img
        v-else
        :aspect-ratio="16 / 9"
        :contain="isPendingTimelapse || clip.status === MediaStatus.Pending"
        :eager="true"
        :lazy-src="thumbnailUrl"
        :src="thumbnailUrl"
        class="white--text rounded-t-lg"
        gradient="rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)"
        :height="imageHeight"
        @error="handleImageError"
      >
        <div v-if="isPendingTimelapse">
          <v-skeleton-loader :max-height="skeletonHeight" type="image" />
          <v-card-title class="img-title">
            <span class="body-2 break-word">{{
              $t("content.media_hub.timelapse_inprogress_text")
            }}</span>
            <span class="body-2 break-word">
              {{ $t("content.media_hub.timelapse_wait_time_text") }}
            </span>
          </v-card-title>
        </div>
        <v-card-title
          v-else
          class="img-title white--text"
          v-text="clip.title"
        />
      </v-img>
      <v-card-text
        ref="media-card-footer"
        class="py-2 d-flex e-justify-between e-w-full e-align-center !e-text-xs on_background--text"
      >
        <div class="e-flex e-flex-col e-w-full">
          <div v-if="showIntervalDates" class="e-w-full">
            <div class="e-flex e-items-center e-w-full from-to-date-wrapper">
              <div
                class="from-date-indicator e-flex e-justify-start e-items-center e-w-full e-gap-2"
              >
                <EIcon
                  color="on_background--text"
                  icon="arrow-right-from-bracket"
                  size="md"
                />
                <span class="on_background--text"
                  >{{ getDate(clip, clip.fromDate) }}
                </span>
              </div>
              <div
                class="to-date-indicator e-flex e-items-center e-gap-2 e-justify-end e-w-full"
              >
                <span class="on_background--text"
                  >{{ getDate(clip, clip.toDate) }}
                </span>
                <EIcon
                  color="on_background--text"
                  icon="arrow-right-to-bracket"
                  size="md"
                />
              </div>
            </div>
          </div>
          <div class="e-flex e-justify-between e-items-start">
            <div class="e-flex e-flex-col">
              <div class="e-flex e-gap-2 e-items-center">
                <EIcon
                  color="on_background--text"
                  icon="far fa-calendar"
                  size="base"
                />
                <span class="on_background--text">
                  {{ getDate(clip, clip.createdAt) }}
                </span>
              </div>
              <div v-if="clip.cameraName" class="e-flex e-gap-2 e-items-center">
                <EIcon color="on_background--text" icon="video" />
                <span class="on_background--text">
                  {{ clip.cameraName }}
                </span>
              </div>
            </div>
            <div class="e-absolute e-bottom-0 e-right-0 pa-3">
              <div>
                <ETooltip :text="clip.requesterName" position="left">
                  <EAvatar size="xs">
                    {{ getRequesterName(clip.requesterName) }}
                  </EAvatar>
                </ETooltip>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script lang="ts">
import {
  AnalyticsEvent,
  type Camera,
  type DateType,
  type Media,
  MediaStatus,
  MediaType,
} from "@evercam/shared/types"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { camelizeKeys } from "humps"
import Vue, { type PropType } from "vue"
import MediaTypeBadge from "@evercam/shared/components/medias/MediaTypeBadge.vue"

export default Vue.extend({
  name: "MediaClip",
  components: {
    MediaTypeBadge,
  },
  props: {
    media: {
      type: Object as PropType<Media>,
      required: true,
    },
    cameraName: {
      type: String,
      default: "",
    },
    selectedCamera: {
      type: Object as PropType<Camera>,
      default: () => ({}),
    },
    withLoadingAnimation: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    refreshTimeoutMinutes: {
      type: Number,
      default: 15,
    },
    refreshIntervalMs: {
      type: Number,
      default: 5000,
    },
    withRefresh: {
      type: Boolean,
      default: true,
    },
    projectExid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      clip: { ...this.media },
      skeletonHeight: 0,
      MediaStatus,
      imageHeight: "220px",
    }
  },
  computed: {
    routeProjectExid(): string {
      const params = camelizeKeys(this.$route.params) as {
        projectExid?: string
      }

      return params.projectExid || ""
    },
    pendingClip(): Media | null {
      return this.isPending ? this.clip : null
    },
    thumbnailUrl(): string | undefined {
      if (this.isPendingTimelapse || this.clip.status === MediaStatus.Pending) {
        return "/evercam_logo.png"
      }

      return this.$imgproxy.get720pResizedImageUrl(this.clip?.thumbnailUrl)
    },
    showIntervalDates(): boolean {
      return [
        MediaType.Compare,
        MediaType.Timelapse,
        MediaType.Clip,
        MediaType.LocalClip,
      ].includes(this.clip.type)
    },
    isFailed(): boolean {
      return this.clip.status === MediaStatus.Failed
    },
    isPending(): boolean {
      return (
        [
          MediaStatus.Pending,
          MediaStatus.Creating,
          MediaStatus.Processing,
        ].includes(this.clip.status as MediaStatus) && this.withRefresh
      )
    },
    fileExtension(): string {
      return this.clip.fileName?.split(".").pop() || ""
    },
    isDocFile(): boolean {
      return [
        "pdf",
        "doc",
        "docx",
        "csv",
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "txt",
      ].includes(this.fileExtension)
    },
    mediaFileIcon(): string {
      const fileIcons: Record<string, string> = {
        pdf: "fa-file-pdf",
        doc: "fa-file-word",
        docx: "fa-file-word",
        csv: "fa-file-csv",
        ppt: "fa-file-powerpoint",
      }
      const ext = this.fileExtension

      return fileIcons[ext] || "fa-file"
    },
    isPendingTimelapse(): boolean {
      return (
        this.clip.type === MediaType.Timelapse &&
        this.clip.status !== MediaStatus.Completed &&
        this.withRefresh
      )
    },
    isMediaClipClickable(): boolean {
      return this.clickable
    },
    mediaLink(): string | null {
      const mediaUrl = `/v2/projects/${this.routeProjectExid}/media-hub/${this.clip.exid}`

      return this.isPending ? null : mediaUrl
    },
    getMediaLink(): string | null | undefined {
      return this.isMediaClipClickable ? this.mediaLink : undefined
    },
    failedMediaPlaceholderText(): string {
      return `${this.$t("content.media_hub.create_clip_error")}${Object.keys(
        MediaType
      )
        .find((key) => MediaType[key] === this.clip.type)
        ?.replace(/([A-Z])/g, " $1")
        .trim()
        .toLowerCase()}`
    },
  },
  watch: {
    pendingClip: {
      immediate: true,
      async handler(pendingClip) {
        if (!pendingClip || !this.withRefresh) {
          return
        }
        const currentTime = new Date().getTime()
        let clipCreatedTime = new Date(pendingClip.createdAt).getTime()
        const timeDifferenceInMilliseconds = Math.abs(
          currentTime - clipCreatedTime
        )
        const timeDifferenceInMinutes = Math.floor(
          timeDifferenceInMilliseconds / (1000 * 60)
        )

        if (timeDifferenceInMinutes < this.refreshTimeoutMinutes) {
          const pendingClipInterval = setInterval(
            () => this.getPendingMediaStatus(pendingClip, pendingClipInterval),
            this.refreshIntervalMs
          )
        }
      },
    },
  },
  methods: {
    getRequesterName(requesterName: string) {
      return requesterName
        ?.split(" ")
        ?.map((name) => name?.[0]?.toUpperCase())
        ?.join("")
    },
    getDate(clip: Media, date: DateType) {
      return this.$moment
        .tz(date, clip.cameraTimezone)
        .format("Do MMM, YYYY | LT")
    },

    async getPendingMediaStatus(item: Media, pendingMediaItemInterval: number) {
      let clip = null as Media | null
      try {
        clip = await EvercamApi.mediaHub.cShow(
          this.projectExid ? this.projectExid : this.routeProjectExid,
          item.exid as string
        )
        if (
          [MediaStatus.Completed, MediaStatus.Failed].includes(clip?.status)
        ) {
          this.clip = clip
          this.$emit("refresh-media-hub")
          clearInterval(pendingMediaItemInterval)
        }
      } catch (error) {
        console.error(error)
      }
    },

    handleImageError(e) {
      console.error("error loading image", e)
      e.target.src = "/unavailable.jpg"
    },
    getHoverStyle(hover = false) {
      if (this.$vuetify.theme.dark) {
        return `lighten-${hover ? "2" : "1"}`
      }

      return `darken-${hover ? "2" : "1"}`
    },
    goToNextPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToNextPage)
    },
    goToPreviousPage() {
      this.$analytics.saveEvent(AnalyticsEvent.MediaHubGoToPreviousPage)
    },
    onResize(entries: ResizeObserverEntry) {
      this.imageHeight = entries.contentRect.width / 2.46 + "px"
    },
  },
})
</script>

<style lang="scss" scoped>
.file-clip-background {
  background-image: linear-gradient(to bottom, #c9c9c9, #686868);
}

@media (max-width: 1160px) and (min-width: 768px) {
  .from-to-date-wrapper {
    flex-direction: column;
    gap: 0.25rem;
  }
  .to-date-indicator {
    flex-direction: row-reverse;
  }
}

.v-card--loading {
  cursor: progress;
  opacity: 0.5;
}

.img-title {
  bottom: 0;
  position: absolute;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  max-width: 100% !important;
  display: block !important;
  letter-spacing: normal !important;
  font-size: 1rem !important;
  word-break: break-word !important;
  line-height: inherit !important;
  font-weight: bold !important;
  width: 100%;
}

.img-title:hover {
  white-space: normal !important;
}

::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100vh;
  }
}

//.media-file-type-badge {
//  position: absolute !important;
//  z-index: 2;
//  margin: 0.5rem;
//  color: #fff;
//}

.media-append-top {
  position: absolute !important;
  z-index: 2;
  margin: 0.5rem;
  color: #fff;
}

.gap-1 {
  gap: 0.25rem !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}
</style>
