<template>
  <div>
    <span class="e-font-medium e-text-sm">Schedule</span>
    <ERadioGroup
      v-model="selectedScheduleType"
      :options="scheduleTypeOptions"
      :dark="$vuetify.theme.dark"
      :disabled="disabled"
    />
    <div
      v-if="selectedScheduleType === timelapseScheduleType.Custom"
      class="e-mt-4"
    >
      <span class="e-font-medium e-text-sm e-mb-4"
        >Select your custom schedule</span
      >
      <ESchedulePicker
        v-model="currentValue"
        :schedule-type="timelapseScheduleType.WorkingHours"
        :dark="$vuetify.theme.dark"
        edit-mode
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { TimelapseScheduleType } from "@evercam/shared/types"
import { schedules } from "@evercam/ui"
import { formField, ESchedulePicker } from "@evercam/ui"

export default Vue.extend({
  name: "Schedule",
  components: {
    ESchedulePicker,
  },
  mixins: [formField],
  data() {
    return {
      timelapseScheduleType: TimelapseScheduleType,
      selectedScheduleType: TimelapseScheduleType.WorkingHours,
    }
  },
  computed: {
    scheduleTypeOptions() {
      return [
        {
          label: this.$t("content.timelapse.continuous") as string,
          value: TimelapseScheduleType.Continuous,
          description: this.$t("content.timelapse.continuous_description"),
        },
        {
          label: this.$t("content.timelapse.working_hours") as string,
          value: TimelapseScheduleType.WorkingHours,
          description: this.$t("content.timelapse.working_hours_description"),
        },
        {
          label: this.$t("content.timelapse.custom") as string,
          value: TimelapseScheduleType.Custom,
          description: this.$t("content.timelapse.custom_description"),
        },
      ]
    },
  },
  watch: {
    selectedScheduleType: {
      immediate: true,
      handler(schedule) {
        switch (schedule) {
          case TimelapseScheduleType.Custom:
            break
          case TimelapseScheduleType.Continuous:
            this.currentValue = schedules.continuous
            break
          case TimelapseScheduleType.WorkingHours:
            this.currentValue = schedules.workingHours
            break
        }
      },
    },
  },
})
</script>
