<template>
  <v-select
    v-model="currentValue"
    :label="label"
    class="evercam-field caption"
    :items="items"
    :disabled="disabled"
    :error-messages="errors"
    :required="required"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #item="{ item, attrs, on }">
      <v-list-item
        class="d-flex align-center e-justify-start"
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-icon v-if="item.image" class="height-auto mr-2">
          <EAvatar size="Sm" :image="item.image" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title
            class="caption"
            v-text="item.title"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import { formField } from "@evercam/ui"

export default Vue.extend({
  name: "SelectField",
  mixins: [formField],
  props: {
    items: {
      type: Array as PropType<
        { title: string; value: string; image: string }[]
      >,
      required: true,
    },
  },
})
</script>

<style>
.v-menu__content {
  z-index: 9999 !important;
}

.v-label {
  font-size: 14px;
}
</style>
