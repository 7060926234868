<template>
  <div
    v-if="missingFields && missingFields.length > 0"
    class="copilot-missing-fields-form e-rounded-lg e-p-4 surface e-min-w-[400px] e-mt-4 e-flex e-flex-col e-gap-4 e-chat-append-message__background"
  >
    <component
      :is="getComponentType(name, type)"
      v-for="({ name, label, type, ...rest }, index) in missingFields"
      :key="name + '_' + index"
      v-model="values[name]"
      :label="label"
      :items="getMissingFieldItems({ name, label, type, ...rest })"
      :options="durationOptions"
      :disabled="isFormDisabled(name)"
      color="primary"
      size="base"
      :menu-props="{ bottom: true, offsetY: true }"
      :right="true"
      :min-date="startDate"
      :max-date="new Date().toISOString()"
      item-text="title"
      item-value="value"
      hide-details
      outlined
      dense
      solo
      flat
    />
    <button
      v-if="!disabled"
      type="button"
      class="e-chat-input__submit-btn primary e-inline-flex e-items-center e-justify-center e-px-3 e-py-2 e-cursor-pointer e-rounded-md disabled:e-cursor-not-allowed disabled:e-opacity-75 e-border-none focus:e-outline-none"
      :disabled="
        Object.values(values).some(
          (value) => value === null || value === undefined
        )
      "
      @click="onSubmit"
    >
      Send
    </button>
  </div>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import {
  CopilotMissingFieldComponent,
  CopilotMissingFieldName,
  CopilotMissingFieldsLabels,
  CopilotMissingFieldType,
  CopilotToolId,
  ProjectFeatureFlag,
  type CamerasByExid,
  type CopilotMissingField,
  type ProjectsByExid,
} from "@evercam/shared/types"
import DateTimeField from "@evercam/shared/components/formFields/DateTimeField.vue"
import SwitchField from "@evercam/shared/components/formFields/SwitchField.vue"
import ScheduleField from "@evercam/shared/components/formFields/ScheduleField.vue"
import SelectField from "@evercam/shared/components/formFields/SelectField.vue"
import MultiOptionsField from "@evercam/shared/components/formFields/MultiOptionsField.vue"
export default Vue.extend({
  name: "CopilotMissingFieldsForm",
  components: {
    DateTimeField,
    SwitchField,
    SelectField,
    ScheduleField,
    MultiOptionsField,
  },
  props: {
    messageId: {
      type: [String, Number],
      required: true,
    },
    missingFields: {
      type: Array as PropType<CopilotMissingField[]>,
      required: true,
    },
    projectsByExid: {
      type: Object as PropType<ProjectsByExid>,
      required: true,
    },
    camerasByExid: {
      type: Object as PropType<CamerasByExid>,
      required: true,
    },
    projectExid: {
      type: String,
      default: "",
    },
    cameraExid: {
      type: String,
      default: "",
    },
    token: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      missingFieldsLabels: CopilotMissingFieldsLabels,
      values: this.missingFields.reduce((acc, field) => {
        acc[field.name] = field.value

        return acc
      }, {} as Record<string, any>),
      componentsByName: {
        [CopilotMissingFieldName.ProjectExid]:
          CopilotMissingFieldComponent.Select,
        [CopilotMissingFieldName.CameraExid]:
          CopilotMissingFieldComponent.Select,
        [CopilotMissingFieldName.FromDate]:
          CopilotMissingFieldComponent.DateTime,
        [CopilotMissingFieldName.ToDate]: CopilotMissingFieldComponent.DateTime,
        [CopilotMissingFieldName.Schedule]:
          CopilotMissingFieldComponent.Schedule,
        [CopilotMissingFieldName.Duration]:
          CopilotMissingFieldComponent.MultiOptions,
      } as Record<string, CopilotMissingFieldComponent>,
      componentsByType: {
        [CopilotMissingFieldType.Boolean]: CopilotMissingFieldComponent.Switch,
      } as Record<string, CopilotMissingFieldComponent>,
    }
  },
  computed: {
    cameras(): { title: string; value: string; image: string }[] {
      const allCameras = Object.entries(this.camerasByExid).map(
        ([key, value]) => ({
          title: value.name,
          value: key,
          image: this.getCameraThumbnailUrl(value.thumbnailUrl),
        })
      )

      if ((!this.values?.projectExid && !this.projectExid) || this.disabled) {
        return allCameras
      }

      return allCameras.filter(
        (camera) =>
          this.camerasByExid[camera.value].project.id ===
          (this.values.projectExid ?? this.projectExid)
      )
    },
    startDate(): string | undefined {
      if (!this.values?.projectExid) {
        return
      }

      return this.camerasByExid[this.values.projectExid!]?.createdAt
    },

    durationOptions() {
      return [
        {
          label: "30s",
          value: 30,
        },
        {
          label: "60s",
          value: 60,
        },
        {
          label: "90s",
          value: 90,
        },
      ]
    },
    missingFieldsProjectExid(): boolean {
      return (
        Object.keys(this.values).includes(
          CopilotMissingFieldName.ProjectExid
        ) && !this.values.projectExid
      )
    },
  },
  methods: {
    getMissingFieldItems(item: CopilotMissingField) {
      return item.name === CopilotMissingFieldName.ProjectExid
        ? this.getProjects(item)
        : this.cameras
    },
    getProjects(missingField: CopilotMissingField) {
      if (
        missingField.toolId.includes(CopilotToolId.GetVehiclesDetections) ||
        missingField.toolId.includes(CopilotToolId.GetVehiclesDetectionsCounts)
      ) {
        return Object.entries(this.projectsByExid)
          .filter(([_key, value]) =>
            value?.featureFlags?.includes(ProjectFeatureFlag.GateReport)
          )
          .map(([key, value]) => ({
            title: value.name,
            value: key,
            image: this.getProjectThumbnailUrl(key),
          }))
      }

      return Object.entries(this.projectsByExid).map(([key, value]) => ({
        title: value.name,
        value: key,
        image: this.getProjectThumbnailUrl(key),
      }))
    },
    getComponentType(fieldName: string, fieldType: string) {
      return (
        this.componentsByName[fieldName] ||
        this.componentsByType[fieldType] ||
        null
      )
    },
    onSubmit() {
      this.$emit("missing-fields-completed", {
        id: this.messageId,
        missingFields: this.values,
      })
    },
    getProjectThumbnailUrl(projectExid: string) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${this.$config.public.apiURL}/projects/${projectExid}/thumbnail?authorization=${this.token}`
      )
    },
    getCameraThumbnailUrl(thumbnailUrl: string) {
      return this.$imgproxy.get360pResizedImageUrl(
        `${thumbnailUrl}?authorization=${this.token}`
      )
    },
    isFormDisabled(name: string): boolean {
      if (this.disabled) {
        return true
      }

      return name !== "projectExid" ? this.missingFieldsProjectExid : false
    },
  },
})
</script>
