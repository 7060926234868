<template>
  <a
    v-if="annotation"
    :href="annotation.url"
    class="copilot-annotation text-decoration-none"
    target="_blank"
  >
    <sup> [{{ annotation.index }}] </sup>
  </a>
</template>

<script lang="ts">
import Vue, { type PropType } from "vue"
import type { ChatMessageAnnotation } from "@evercam/ui"

export default Vue.extend({
  name: "CopilotAnnotation",
  props: {
    annotation: {
      type: [Object, undefined] as PropType<ChatMessageAnnotation | undefined>,
      default: undefined,
    },
  },
})
</script>
