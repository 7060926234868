<template>
  <VueCtkDateTimePicker
    :value="value"
    class="caption ctkdatetimepicker"
    :class="{
      disabled: disabled,
      dark: $vuetify.theme.dark,
    }"
    hint="Date & Time"
    :format="format"
    formatted="DD/MM/YYYY HH:mm"
    no-shortcuts
    no-header
    no-button-now
    v-bind="$attrs"
    :dark="$vuetify.theme.dark"
    @input="$emit('input', $event)"
    v-on="$listeners"
  >
    <v-text-field
      label="URL to Dash"
      class="col-12 date-time-picker__text-field float-left height-45 border-bottom max-width-300 input-text-field"
      :disabled="disabled"
      readonly
      solo
    />
  </VueCtkDateTimePicker>
</template>

<script>
export default {
  name: "CtkDateTimePicker",
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "YYYY-MM-DDTHH:mm:ss.sss",
    },
  },
}
</script>

<style lang="scss" scoped>
.ctkdatetimepicker {
  .max-width-300 {
    max-width: 300px;
  }
  .v-input.border-bottom {
    border: 1.67px solid var(--v-background-lighten3);
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    background: var(--v-background-base);
    max-width: 100%;
    width: 100%;
  }
  .input-text-field {
    &:hover:not(:disabled) {
      border: 1.67px solid var(--v-on_background-darken2);
    }
    &:disabled {
      opacity: 0.75;
      cursor: not-allowed !important;
      border: 1.67px solid var(--v-background-lighten3) !important;
      pointer-events: none;
    }
    &::placeholder {
      color: var(--tw-bg-gray-300);
      font-size: 1rem;
    }
  }
  &.disabled {
    opacity: 0.95;
    pointer-events: none;
    cursor: not-allowed !important;
    .v-input {
      pointer-events: none;
      cursor: not-allowed !important;
    }
    .date-time-picker__text-field {
      opacity: 0.95 !important;
      cursor: not-allowed !important;
    }
  }
}
.date-time-picker__text-field:disabled {
  opacity: 0.95 !important;
  cursor: not-allowed !important;
  pointer-events: none;
}
</style>
