<template>
  <v-container
    fluid
    class="e-w-full e-h-full e-overflow-y-auto copilot-conversations pa-0"
  >
    <div v-if="loading" class="d-flex justify-center align-center mt-10">
      <v-progress-circular
        indeterminate
        :size="80"
        :width="5"
        color="primary"
      />
    </div>
    <div v-else>
      <v-card
        v-if="selectedConversation && messages?.length"
        class="e-rounded-lg"
        height="100vh"
        flat
      >
        <v-card-text
          class="d-flex flex-column pa-0 copilot-chat"
          style="height: 90vh"
        >
          <EChat
            :messages="messages"
            :user="selectedConversation.user"
            :header-text="conversationTitle"
            inline-footer
            readonly
            embedded
            :exposed-regexes="exposedMarkdownRegexes"
          >
            <template #header-append>
              <v-btn icon class="mr-4" @click="$router.back()">
                <EIcon icon="times" size="2xl" />
              </v-btn>
            </template>
            <template #message.extraContent="{ message }">
              <div class="feedback-container d-flex flex-column">
                <v-slide-x-transition>
                  <div
                    v-if="isFeedbackVisible(message.id)"
                    class="copilot-conversations__message-feedback pl-4 pr-2 py-2 ml-2 my-2 d-flex e-rounded-md e-bg-gray-100"
                    :class="`feedback--${feedbackByMessageId[message.id].type}`"
                  >
                    <div>
                      {{ feedbackByMessageId[message.id].text }}
                    </div>
                    <v-btn
                      class="e-bg-gray-200 mb-auto ml-2"
                      icon
                      x-small
                      @click="hideFeedback(feedbackByMessageId[message.id])"
                    >
                      <v-icon> fa-times</v-icon>
                    </v-btn>
                  </div>
                </v-slide-x-transition>
              </div>
            </template>
            <template #markdown.regex.timestamp="{ match }">
              <strong
                :data-timestamp="match"
                class="copilot-chat__timestamp cursor-pointer primary--text"
              >
                {{ getFormattedTimestamp(match) }}
              </strong>
            </template>
            <template #markdown.regex.anprThumbnail="{ match }">
              <CopilotAnprThumbnail :token="token" :url="match" />
            </template>
            <template #markdown.regex.cameraExid="{ match }">
              <a :href="getCameraUrl(match)" target="_blank">
                <strong :data-camera-exid="match">
                  {{ getFormattedCameraName(match) }}
                </strong>
              </a>
            </template>
            <template #markdown.regex.annotation="{ match }">
              <CopilotAnnotation :annotation="annotations[match]" />
            </template>
            <template #message.content="{ message }">
              <CopilotToolCallResult
                v-if="message.type === CopilotMessageType.Json"
                :result="message.data"
                :cameras-by-exid="allCamerasByExid"
                :with-refresh="false"
                :with-media-hub-button="false"
                :with-export-chart-btn="false"
              />
            </template>
            <template #message.append="{ message }">
              <div class="e-flex e-w-full e-justify-end">
                <CopilotMissingFieldsForm
                  v-if="message.type === 'missingFieldsRequest'"
                  :message-id="message.id"
                  :missing-fields="message.data"
                  :projects-by-exid="projectsByExid"
                  :cameras-by-exid="camerasByExid"
                  :token="token"
                  disabled
                />
              </div>
            </template>
          </EChat>
        </v-card-text>
        <ETimeline
          v-if="showTimeline"
          :start-date="selectedConversation.startDate"
          :end-date="selectedConversation.endDate"
          :min-date="messageTimelineDomain.startDate"
          :max-date="messageTimelineDomain.endDate"
          :selected-timestamp="messages[messages.length - 1]?.timestamp"
          :events-groups="messagesTimelineGroups"
          :markers="messagesFeedbackMarkers"
          class="w-100"
          :show-labels="false"
          @event-clicked="onTimelineMessageClicked"
          @marker-clicked="onFeedbackMarkerClicked"
        >
          <template #eventTooltip="{ event, active }">
            <div
              v-if="active"
              class="copilot-conversations__messages__tooltip pa-2 px-3 e-rounded-md"
            >
              <div class="d-flex">
                <strong class="mr-2">Content: </strong>
                <ETruncatedDiv :width="110">
                  {{ event.content }}
                </ETruncatedDiv>
              </div>
              <div class="d-flex">
                <strong class="mr-2">Time: </strong>
                {{ $moment(event.timestamp).format("YYYY-MM-DD HH:mm:ss") }}
              </div>
            </div>
          </template>
        </ETimeline>
      </v-card>
      <v-card
        v-if="selectedConversation && !messages.length"
        class="e-rounded-lg"
      >
        <v-card-title> Empty chat session</v-card-title>

        <div class="px-6 pb-6">
          <div class="d-flex pb-1">
            <strong class="pr-2">User:</strong>
            <div>{{ selectedConversation.user }}</div>
          </div>
          <div class="d-flex py-1">
            <strong class="pr-2">Start date:</strong>
            <div>{{ selectedConversation.startDate }}</div>
          </div>
          <div class="d-flex py-1">
            <strong class="pr-2">End date:</strong>
            <div>{{ selectedConversation.endDate }}</div>
          </div>
          <div class="pt-2 py-1">
            Possible scenarios:
            <ul>
              <li>The user opened Copilot but didn't send any message.</li>
              <li>The app crashed (frontend or backend).</li>
              <li>The internet connection was lost.</li>
            </ul>
          </div>
        </div>
      </v-card>
    </div>
    <v-dialog
      v-model="stepsDialog"
      scrollable
      :max-width="dialogWidth"
      content-class="copilot-conversations__messages"
      transition="scroll-y-transition"
    >
      <CopilotMessageStepsDiagram
        :steps="messageSteps"
        :copilot-provider="copilotProvider"
        @close="onCloseSteps"
      />
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import { AdminApi } from "@evercam/shared/api/adminApi"
import {
  Camera,
  CamerasByExid,
  CopilotMessageAuthor,
  CopilotMessageType,
  CopilotMissingField,
  CopilotProvider,
  CopilotToolId,
  ProjectsByExid,
} from "@evercam/shared/types"
import {
  ChatMessageRole,
  ChatMessageType,
  type EMarkdownRegex,
  FeedbackType,
  TimelineChartType,
} from "@evercam/ui"
import { useAccountStore } from "@evercam/admin/stores/account"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import CopilotMessageStepsDiagram from "~/components/copilot/CopilotMessageStepsDiagram.vue"
import CopilotToolCallResult from "@evercam/shared/components/copilot/CopilotToolCallResult.vue"
import CopilotAnprThumbnail from "@evercam/shared/components/copilot/CopilotAnprThumbnail.vue"
import { mapStores } from "pinia"
import CopilotAnnotation from "@evercam/shared/components/copilot/CopilotAnnotation.vue"
import CopilotMissingFieldsForm from "@evercam/shared/components/copilot/CopilotMissingFieldsForm.vue"

export default Vue.extend({
  name: "CopilotMessages",
  components: {
    CopilotAnnotation,
    CopilotAnprThumbnail,
    CopilotToolCallResult,
    CopilotMessageStepsDiagram,
    CopilotMissingFieldsForm,
  },
  data() {
    return {
      stepsDialog: false,
      selectedConversation: null,
      messages: [],
      messageSteps: null,
      showTimeline: true,
      hiddenFeedback: {},
      usersCameras: [] as Camera[],
      annotations: {} as Record<string, { url: string; index: number }>,
      loading: false,
      camerasByExid: {} as CamerasByExid,
      projectsByExid: {} as ProjectsByExid,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    token(): string {
      return this.accountStore.token
    },
    exposedMarkdownRegexes(): EMarkdownRegex[] {
      return [
        {
          type: "anprThumbnail",
          regex: /(grt_.*_grt)/gm,
        },
      ]
    },
    cameraExidRegex(): RegExp {
      const exids = Object.keys(this.camerasByExid)

      return new RegExp(`(?<!/)(${exids.join("|")})(?!/)`, "gmi")
    },
    CopilotMessageType() {
      return CopilotMessageType
    },
    conversationId(): string {
      return this.$route.params.id
    },
    copilotProvider() {
      return this.selectedConversation?.model.includes(CopilotProvider.Gemini)
        ? "Gemini"
        : "ChatGPT"
    },
    dialogWidth() {
      if (!this.selectedConversation?.Message.length) {
        return 400
      } else {
        return 950
      }
    },
    conversationTitle() {
      const conversationDate = this.$moment(
        this.selectedConversation.startDate
      ).format("MMM Do YYYY")

      const conversationStartTime = this.$moment(
        this.selectedConversation.startDate
      ).format("HH:mm:ss")

      const conversationEndTime = this.$moment(
        this.selectedConversation.endDate
      ).format("HH:mm:ss")

      return `(${this.selectedConversation.user}) - ${conversationDate} - from ${conversationStartTime} to ${conversationEndTime} - ${this.selectedConversation?.model}`
    },
    messageTimelineDomain() {
      return {
        start: new Date(
          new Date(this.selectedConversation.startDate).getTime() - 60_000
        ).toISOString(),
        end: new Date(
          new Date(this.selectedConversation.endDate).getTime() + 60_000
        ).toISOString(),
      }
    },
    messagesTimelineGroups() {
      return {
        chatMessages: {
          label: "",
          color: "#aaa",
          chartType: TimelineChartType.Dots,
          dotsSize: 20,
          height: 45,
          events: this.messages.map((m) => ({
            ...m,
            timestamp: m.timestamp,
            color:
              m.role === CopilotMessageAuthor.Copilot
                ? TimelineColors.copilot
                : TimelineColors.user,
          })),
        },
      }
    },
    messagesFeedbackMarkers() {
      return this.messages.reduce((acc, m) => {
        if (!m?.MessageFeedback || !m.MessageFeedback.length) {
          return acc
        }
        const feedback = m.MessageFeedback[0].feedback

        return [
          ...acc,
          {
            ...feedback,
            messageId: m.id,
            timestamp: feedback.createdAt,
            label: feedback.type === FeedbackType.Positive ? "👍" : "👎",
            color:
              feedback.type === FeedbackType.Positive
                ? "rgb(5, 150, 105)"
                : "rgb(239, 68, 68)",
            textColor: "#fff",
            isDraggable: false,
            className: "copilot-conversations__messages__feedback",
          },
        ]
      }, [])
    },
    feedbackByMessageId() {
      return this.messages.reduce((acc, m) => {
        if (!m?.MessageFeedback || !m.MessageFeedback.length) {
          return acc
        }
        const feedback = m.MessageFeedback[0].feedback

        return {
          ...acc,
          [m.id]: feedback,
        }
      }, {})
    },
    allCamerasByExid(): CamerasByExid {
      return this.usersCameras.reduce(
        (acc, camera) => ({
          ...acc,
          [camera.exid]: camera,
        }),
        {}
      )
    },
  },
  async created() {
    this.loading = true
    await this.initConversation()
    this.loading = false
  },

  methods: {
    async initConversation() {
      await Promise.all([this.fetchConversation(), this.getUsersCameras()])
    },
    async fetchConversation() {
      try {
        const response = await EvercamLabsApi.copilot.getOneConversation(
          this.$route.params.id
        )
        this.selectedConversation = response
        this.processMessages(response.Message)
      } catch (error) {
        console.error("Failed to fetch conversations:", error)
      }
    },

    processMessages(messages) {
      messages.forEach((message) => {
        if (message.MessageStep?.length) {
          this.processMessageSteps(message)
        }
        this.messages.push(message)
      })
    },

    processMessageSteps(message) {
      let missingFieldsData = {
        openAICallId: "",
        fields: {} as Record<string, CopilotMissingField>,
      }

      // Process each step in order
      message.MessageStep.forEach((step) => {
        if (step.step === "exec_function_call") {
          this.handleExecFunctionCall(step, missingFieldsData)
        } else if (step.step === "submit_tool_outputs_to_llm") {
          this.handleToolOutputs(step, message.timestamp, missingFieldsData)
        }
      })

      // Handle tool call response if present
      const toolCallResponse = message.MessageStep.find(
        (step) => step.step === "send_raw_tool_call_response"
      )
      if (toolCallResponse) {
        this.handleToolCallResponse(toolCallResponse, message.timestamp)
      }
    },

    handleExecFunctionCall(step, missingFieldsData) {
      try {
        const args = JSON.parse(step.args[0])
        if (args.tool.id !== "requestMissingFields") return

        missingFieldsData.openAICallId = args.openAICallId
        args.input.missingFields.forEach((field) => {
          missingFieldsData.fields[field.name] = {
            name: field.name,
            type: field.type,
            toolId: field.toolId,
            value: "",
            label: this.$t(`copilot.labels.${field.name}`) as string,
          }
        })
      } catch (error) {
        console.error("Failed to process exec function call:", error)
      }
    },

    handleToolOutputs(step, timestamp, missingFieldsData) {
      try {
        const args = JSON.parse(step.args[0])
        if (args[0].tool_call_id !== missingFieldsData.openAICallId) return

        const submittedData = JSON.parse(args[0].output)

        // Update field values and fetch related data
        Object.entries(submittedData).forEach(([key, value]) => {
          if (missingFieldsData.fields[key].name === "cameraExid") {
            this.getCamera(value as string)
          }
          if (missingFieldsData.fields[key].name === "projectExid") {
            this.getProject(value as string)
          }
          missingFieldsData.fields[key].value = value
        })

        // Add missing fields message
        this.messages.push({
          id: args[0].tool_call_id,
          data: Object.values(missingFieldsData.fields),
          content: this.formatMissingFieldsContent(missingFieldsData.fields),
          type: "missingFieldsRequest",
          role: ChatMessageRole.User,
          timestamp: this.$moment(timestamp)
            .subtract(1, "second")
            .toISOString(),
          withActions: false,
        })
      } catch (error) {
        console.error("Failed to process tool outputs:", error)
      }
    },

    handleToolCallResponse(step, timestamp) {
      this.messages.push({
        id: step.id,
        data: JSON.parse(step.args[0]),
        content: "",
        type: ChatMessageType.Json,
        role: ChatMessageRole.Copilot,
        timestamp: this.$moment(timestamp).subtract(3, "second").toISOString(),
        withActions: false,
      })
    },

    formatMissingFieldsContent(
      fields: Record<string, CopilotMissingField>
    ): string {
      return (
        Object.values(fields)
          .map((field) => this.$t(`copilot.labels.${field.name}`))
          .join(", ") + " Provided."
      )
    },
    async getUsersCameras() {
      try {
        const response = await AdminApi.cameras.getCameras({
          ownerEmail: this.selectedConversation.user,
        })
        this.usersCameras = response.items
      } catch (error) {
        console.error("Failed to fetch cameras:", error)
      }
    },
    isChartResult(message) {
      return message.toolId === CopilotToolId.RenderCharts
    },
    isFeedbackVisible(messageId) {
      const feedback = this.feedbackByMessageId?.[messageId]

      return feedback && !this.hiddenFeedback?.[feedback?.id]
    },
    hideFeedback(feedback) {
      this.hiddenFeedback = {
        ...this.hiddenFeedback,
        [feedback.id]: true,
      }
    },
    onTimelineMessageClicked({ event }) {
      this.messageSteps = null
      const { id } = event
      this.highlightMessage(id)

      if (event.role === CopilotMessageAuthor.Copilot) {
        this.viewMessageSteps(event)
      }
    },
    highlightMessage(id) {
      const messageElement = this.getTargetMessageElement(id)
      if (messageElement) {
        messageElement.scrollIntoView({ behavior: "smooth" })
        messageElement.classList.add(
          "copilot-conversations__messages__message--highlight"
        )
        this.$setTimeout(() => {
          messageElement.classList.remove(
            "copilot-conversations__messages__message--highlight"
          )
        }, 2500)
      }
    },
    async getCamera(exid: string) {
      try {
        const response = await AdminApi.cameras.getCameraDetails(exid)
        this.camerasByExid = {
          [exid]: response,
        }
      } catch (e) {
        console.error("Failed to fetch camera:", e)
      }
    },
    async getProject(exid: string) {
      try {
        const { data } = await AdminApi.projects.getProject(exid)
        this.projectsByExid = {
          [exid]: data,
        }
      } catch (e) {
        console.error("Failed to fetch camera:", e)
      }
    },
    async viewMessageSteps(message) {
      this.stepsDialog = true
      this.messageSteps = message.MessageStep
    },
    getTargetMessageElement(id) {
      return document.querySelector(`[data-message-id="${id}"]`)
    },
    onFeedbackMarkerClicked(feedback) {
      this.highlightMessage(feedback.messageId)
      this.hiddenFeedback[feedback.id] = false
    },
    getConversationDuration(conversation) {
      const start = this.$moment(conversation.startDate)
      const end = this.$moment(conversation.endDate)
      const duration = this.$moment.duration(end.diff(start))
      const minutes = duration.minutes()
      const seconds = duration.seconds()

      if (minutes >= 1) {
        return `${minutes}m ${seconds}s`
      } else {
        return `${seconds}s`
      }
    },
    getFormattedTimestamp(timestamp: string) {
      return this.$moment(timestamp.replaceAll("$", "")).format(
        "dddd DD-MMM-YYYY h:mmA"
      )
    },
    onCloseSteps() {
      this.stepsDialog = false
      this.messageSteps = null
    },
  },
})
</script>

<style lang="scss">
$positive-color: rgb(5, 150, 105);
$negative-color: rgb(239, 68, 68);

.e-chat-container {
  min-height: 500px;
}

.e-chat-body {
  overflow-y: auto;
  min-height: 80vh !important;
}

.copilot-missing-fields-form {
  width: 600px;
}

.copilot-conversations {
  &__message-feedback {
    height: fit-content;
    max-width: 500px;
    display: block;
    font-weight: 500;
    font-style: italic;
    position: sticky;
    top: 20px;
    right: 0;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      border-right: 8px solid #f3f4f6;
      left: -11px;
      top: 13px;
    }

    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
      left: -9px;
      top: 13px;
    }

    &.feedback--positive {
      color: $positive-color;
      border: 1px solid $positive-color;

      &:before {
        border-right: 11px solid #059669;
      }

      &:after {
        border-right: 10px solid #f3f4f6;
      }
    }

    &.feedback--negative {
      color: $negative-color;
      border: 1px solid $negative-color;

      &:before {
        border-right: 11px solid rgb(239, 68, 68);
      }

      &:after {
        border-right: 10px solid #f3f4f6;
      }
    }
  }

  .copilot-chat {
    .e-chat-header,
    .e-chat-input__textarea,
    .e-chat-input__submit-btn,
    .e-chat-input__voice-btn {
      background: var(--v-background-base);
    }

    .e-chat-body,
    .e-chat-input__textarea {
      border-color: var(--dash-border-color);
    }

    .e-chat-input {
      &__textarea {
        height: 48px;
      }

      &__submit-btn {
        margin-bottom: 4px;
        margin-right: 3px;
      }
    }

    .e-chat-append-message {
      &__background {
        background: var(--v-background-base);
      }
    }

    .e-markdown {
      hr {
        opacity: 0.3;
        margin: 1em 0;
      }

      .e-prose {
        table {
          border-collapse: collapse;
        }

        thead {
          border-bottom: 1px solid var(--dash-border-color);

          th {
            height: 20px;
            max-height: 20px;
          }
        }

        tbody tr {
          max-height: 70px;
          height: 70px;
          overflow: hidden;
          border-bottom: 1px solid var(--dash-border-color);
        }

        tbody td {
          padding-top: 0.04em !important;
          padding-bottom: 0.01em !important;
          max-height: 60px;
          height: 60px;
          vertical-align: middle;
        }

        table img {
          margin-top: 0;
          margin-bottom: 0;
          display: block;
          object-fit: contain;
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
}

.feedback-container {
  align-items: baseline;
  position: sticky;
  top: 20px;
  right: 0;
  height: fit-content;
}

.copilot-conversations__messages {
  overflow: visible;

  .e-timeline__svg-container {
    border-radius: 0;
    border-top: 1px solid #e8e8e8;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &__tooltip {
    height: 54px;
    width: 190px;
    background: rgb(243, 244, 246);
    transform: translate(-50%, -10%);
  }

  .e-chat-wrapper {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden;
  }

  .e-chat-message {
    transition: background-color 0.2s;
  }

  .copilot-conversations__messages__message--highlight {
    background-color: rgba(24, 103, 192, 0.1);
    border-radius: 10px;
    animation: flash 2s ease-in-out forwards;
  }

  @keyframes flash {
    0%,
    50%,
    100% {
      background-color: transparent;
    }
    25%,
    75% {
      background-color: rgba(24, 103, 192, 0.1);
    }
  }
}
</style>
