<template>
  <div
    class="media-file-type-badge e-text-white e-w-max e-flex e-justify-center e-items-center e-gap-2 e-rounded-lg e-px-2 badge-bg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <EIcon :icon="clipTextIcon.icon" />
    <span class="caption font-weight-bold">{{ clipTextIcon.text }}</span>
  </div>
</template>

<script lang="ts">
import Vue from "vue"
import { MediaType } from "@evercam/shared/types"

export default Vue.extend({
  name: "MediaTypeBadge",
  props: {
    mediaItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    clipTextIcon(): Record<string, string> {
      if (/youtube\.com/.test(this.mediaItem.url!)) {
        return { icon: "fab fa-youtube", text: "Youtube" }
      }
      if (/vimeo\.com/.test(this.mediaItem.url!)) {
        return { icon: "fab fa-vimeo", text: "Vimeo" }
      }
      if (this.mediaItem.type === MediaType.ExternalUrl) {
        return { icon: "fas fa-link", text: "External Clip" }
      }
      const defaultIcon = {
        icon: this.$device.isIos ? "fas fa-clock" : "fas fa-history",
        text: "Timelapse",
      }

      const iconMap: Record<string, Record<string, string>> = {
        [MediaType.Compare]: {
          icon: this.$device.isIos ? "fas fa-compress" : "fas fa-left-right",
          text: "Compare",
        },
        [MediaType.Clip]: { icon: "fas fa-cloud", text: "Cloud Clip" },
        [MediaType.LocalClip]: {
          icon: "fas fa-hard-drive",
          text: "Local Clip",
        },
        [MediaType.XRay]: { icon: "fas fa-x-ray", text: "X-Ray" },
        [MediaType.EditedImage]: { icon: "fas fa-image", text: "Image" },
        [MediaType.File]: {
          icon: "fas fa-file-arrow-up",
          text: "Uploaded File",
        },
      }

      return iconMap?.[this.mediaItem.type] || defaultIcon
    },
  },
})
</script>

<style scoped>
.badge-bg {
  background-color: #0006;
}
</style>
